import {Injectable,OnInit} from '@angular/core';
import {HttpClientService} from './httpClient.service';
import {Router} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {ApiListService} from './apiList.service';

@Injectable({
    providedIn:'root'
})
export class MyAccountService  implements OnInit
{
    public get = false;
    constructor(private httpClient:HttpClientService ,private router:Router,private cookie:CookieService,private api:ApiListService)
    {
        

    }
    ngOnInit() 
    {
     
     
    }
    
    profileImage(obj)
    {
        return this.httpClient.putImage(this.api.myAccProfileImage,obj);
    }

    getProfile(url)
    {
        return this.httpClient.get(url);
    }
    updateProfile(url,obj){
        return this.httpClient.put(url,obj)
    }
    post(url,obj){
        return this.httpClient.post(url,obj)
        
    }
    put(url,obj){
        return this.httpClient.put(url,obj);
   
    }
    getData(url)
    {
        return this.httpClient.get(url);
    }
    
    updateFamilyByUserName(url,obj){
        return this.httpClient.put(url,obj)
    }

    deleteFamilyMember(url){
        return this.httpClient.delete(url)
    }

}