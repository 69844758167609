import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
@Injectable()
export class ApiListService {
    public baseUrl = environment.APIEndpoint;


    public ucmsbaseUrl = 'https://ucmsapioffline.workplaceoptions.com/';
    public WpobaseUrl = 'https://api.workplaceoptions.com/';
    public ContentbaseUrl = 'https://www.powerflexweb.com/';
    public lyricBaseUrl = environment.lyricEndpoint;

    login = this.baseUrl + 'allyuser/user_login/';
    registration = this.baseUrl + 'salesforce/signupstep/';
    create = this.baseUrl + 'allyuser/createuser/';
    masterDropDown = this.baseUrl + 'helpers/dropdownmasterfaqs/'
    review = this.baseUrl + 'helpers/reviews/';
    myAccProfileImage = this.baseUrl + 'allyuser/updateprofilepic/';
    userProfile = this.baseUrl + 'allyuser/userprofile/';
    logOut = this.baseUrl + 'allyuser/user_logout/';
    userProgileUpdate = this.baseUrl + 'allyuser/profileupdate/';
    wallet = this.baseUrl + 'allyuser/createWalletCard/';
    fileupload = this.baseUrl + 'helpers/fileupload/';
    tokenupdate = this.baseUrl + 'mdlive/mdlive_login/';
    user_lifestyle = this.baseUrl + 'allyuser/user_lifestyle/';
    addFamailyMember = this.baseUrl + 'salesforce/creating_family/';
    authDeauth = this.baseUrl + 'allyuser/authorizeprimary/';
    ChangePassword = this.baseUrl + 'allyuser/change_password/';
    edocProviderList = this.baseUrl + 'helpers/care_provider_list/';
    postMessage = this.baseUrl + 'helpers/postMessage/';
    headerMessage = this.baseUrl + 'helpers/headerMessage/';
    indevisulalMessage = this.baseUrl + 'helpers/getMessage/?message_id='
    saveErrorLog = this.baseUrl + "allyadmin/save_errorlogs/"
    confirmationRating = this.baseUrl + "allyadmin/appointment_confirmation_rating/"
    verifyEmail = this.baseUrl + 'allyuser/verify_email/';
    verifyOtp = this.baseUrl + 'allyuser/verify_otp/';
    verifyUser = this.baseUrl + 'allyuser/verify_user/';
    getState = this.baseUrl + 'allyadmin/state_list/'
    getCity = this.baseUrl + 'allyadmin/state_record/?state='
    userverify = this.baseUrl + 'allyuser/userverify/';
    switchUser = this.baseUrl + 'allyuser/switch_user/';
    setPassword = this.baseUrl + 'allyuser/password_set/';
    registerPreenrolled = this.baseUrl + 'salesforce/pre_enrolled_dependent/';
    dependentDetail = this.baseUrl + 'salesforce/dependent_detail/';
    forgotpass = this.baseUrl + 'allyuser/forget_password/';
    setNewPassword = this.baseUrl + 'allyuser/forget_password_set/';
    forgotUsername = this.baseUrl + 'allyuser/forget_username/';
    emailNotificationUpdate = this.baseUrl + 'helpers/emailNotificationdetailview/';
    emailNotificationGet = this.baseUrl + 'helpers/emailNotificationlistview/';
    pushNotificationUpdate = this.baseUrl + 'helpers/pushNotificationdetailview/';
    pushNotificationget = this.baseUrl + 'helpers/pushNotificationlistview/';
    insuranceInformation = this.baseUrl + 'salesforce/insurance_info/';
    therapistData = this.baseUrl + 'mdlive/speciality_list/?provider_type=therapist';
    psychiatristData = this.baseUrl + 'mdlive/speciality_list/?provider_type=psychiatrist';
    helpSupport = this.baseUrl + 'salesforce/customer_support/';
    messageHelpSupport = this.baseUrl + 'salesforce/customer_support_conversation/';
    emailVerify = this.baseUrl + 'allyuser/email_verify/';
    scheduleAppointment = this.baseUrl + 'mdlive/scheduleappointment/';
    cancelAppointmentEmail = this.baseUrl + 'mdlive/cancelappointment/';
    readMessages = this.baseUrl + 'allyuser/readmessages/';
    cobranding = this.baseUrl + 'salesforce/cloneneworgassets/';
    deleteWalletCard = this.baseUrl + 'allyuser/createWalletCard/';
    medicalBillEmail = this.baseUrl + 'helpers/medicalbill/';
    reatTime = this.baseUrl + 'allyuser/realtime-user-verify/';
    latestMessage = this.baseUrl + 'salesforce/customer-support/message-count/';
    usernameVerifyUpdate = this.baseUrl + 'allyuser/usernameverify/';
    emailVerifyUpdate = this.baseUrl + 'allyuser/emailverify/';
    walletProviders = this.baseUrl + 'helpers/provider-type/providertype/';
    uploadWalletDoc = this.baseUrl + 'allyuser/wallet-card-doc/';

    updateFamilyById = this.baseUrl + 'salesforce/dependent/?dep_id=';
    updateFamilyByUserName = this.baseUrl + 'salesforce/dependent/?username=';
    sendEmail = this.baseUrl + 'allyuser/send_mailagent/'
    starRating = this.baseUrl + 'allyadmin/star_rating/'
    getRating = this.baseUrl + 'allyadmin/get_star_rating/'
    getCallMethod = this.baseUrl + 'allyadmin/get_call_method/'

    /********************MDlive************************* */


    public baseUrlMdlive = environment.APIEndpointMDLive
    imageBaseUrl = 'https://stage-rest-api.mdlive.com/api/v1';
    patient = this.baseUrlMdlive + 'api/v2/patients/';
    patientV1 = this.baseUrlMdlive + 'api/v1/patients/';
    userloginvarifaction = this.baseUrlMdlive + 'api/v1/mobile_user_auth/auth_token/';
    chief_complaints = this.baseUrlMdlive + 'api/v1/patients/chief_complaints?';
    documents = this.baseUrlMdlive + 'api/v2/patients/';
    documents_v1 = this.baseUrlMdlive + 'api/v1/patients/';
    documentsShow = this.baseUrlMdlive + 'api/v1/patients/';
    upcomingAppointement = this.baseUrlMdlive + 'api/v1/patients/';
    medical_conditions = this.baseUrlMdlive + 'api/v1/patients/';
    madicationSerch = this.baseUrlMdlive + 'api/v2/medications/search';
    allergiesSerch = this.baseUrlMdlive + 'api/v1/allergies/search';
    clinical_concepts = this.baseUrlMdlive + 'api/v1/clinical_concepts/';
    surgery_names = this.baseUrlMdlive + 'api/v2/surgery_names';
    get_dependant_types = this.baseUrlMdlive + 'api/v1/patients/family_members/get_dependant_types';
    insurance_payers = this.baseUrlMdlive + 'api/v1/insurance_payers';
    provider = this.baseUrlMdlive + 'api/v2/providers/';
    provider_v1 = this.baseUrlMdlive + 'api/v1/providers/';
    auth_extend = this.baseUrlMdlive + 'api/v1/sso_auth/auth_extend';
    retrive_all_conversion_of_patient = this.baseUrlMdlive + 'api/v2/patients/';
    retrive_message_of_doctor_patients = this.baseUrlMdlive + 'api/v2/patients/';
    send_message = this.baseUrlMdlive + 'api/v1/patients/';
    message_mark_read = this.baseUrlMdlive + 'api/v2/patients/';
    checkPayment = this.baseUrlMdlive + 'api/v2/payments/costs/check?patient_id='
    currentPharmacy = this.baseUrlMdlive + 'api/v1/patients/';
    requestAppointment = this.baseUrlMdlive + 'api/v1/patients/appointments/';
    cancelReqApp = this.baseUrlMdlive + 'api/v2/patients/';
    remindReqApp = this.baseUrlMdlive + 'api/v2/patients/';
    systemNotify = this.baseUrlMdlive + 'api/v1/patients/';
    consultationSummary = this.baseUrlMdlive + 'api/v1/patients/';
    updatePhoneNumber = this.baseUrlMdlive + 'api/v1/patients/';
    switchAppointmentToPhone = this.baseUrlMdlive + 'api/v1/patients/';

    /****************************PCP FLOW********************************** */
    pcpPostUrl = this.baseUrlMdlive + 'api/v1/patients/'
    searchPcp = this.baseUrlMdlive + 'api/v1/health_systems/providers/pcp_search?'


    /**************************Video Calling*************************** */
    connection_params = this.baseUrlMdlive + 'api/v1/appointments/';

    /**************************DDN************************************* */
    ddnBaseUrl = 'https://api.discountdrugnetwork.com/api/';
    getDDNToken = this.ddnBaseUrl + 'auth/login/';
    searchDrug = this.ddnBaseUrl + 'Drug/Autocomplete';
    findDrugs = this.ddnBaseUrl + 'Drug/FindDrugs';
    drugFormStrength = this.ddnBaseUrl + 'Drug/DrugFormStrength';

    //newDesign for Tiles

    tileUrl = this.baseUrl + 'allyadmin/get_custom_tile/?account_id='
    tileSingleData = this.baseUrl + 'allyadmin/tile_details/'
    opportunityCost = this.baseUrl + 'allyadmin/opportunity_cost/?account_id='



    // ucms apis 

    // List of services 
    listOfService = this.ucmsbaseUrl + 'api/client/service'
    serviceRequest = this.ucmsbaseUrl + 'api/client/request/service'

    // wpo api 
    wpoRegister = this.WpobaseUrl + 'api/SB/external/v1/Register/NewUser'

    contentCategories = this.ContentbaseUrl + 'api_content/common/read_mod.php'
    featuredArticleList = this.ContentbaseUrl + 'api_content/common/read_ftr.php'
    recentArticleList = this.ContentbaseUrl + 'api_content/common/read_new.php'
    newForYou = this.ContentbaseUrl + 'api_content/common/read_nfy.php'
    detailsOfArticles = this.ContentbaseUrl + 'api_content/common/read_one.php'
    TotalDataForFilter = this.ContentbaseUrl + 'api_content/common/read_cat.php'
    subcatrgories = this.ContentbaseUrl + 'api_content/common/read.php'
    searchArticle = this.ContentbaseUrl + 'api_content/common/read.php'

    slack = this.baseUrl + 'allyadmin/save_errorlogs/'
    getBenefitCard = this.baseUrl + 'allyadmin/get_benifit_card/'


    // lyric 
    startConsultation = this.baseUrl + 'lyrics/userprofile/'
    getProvider = this.baseUrl + 'lyrics/provider/list/'
    getPharmacy = this.lyricBaseUrl + '/pharmacy/search'
    allergies = this.lyricBaseUrl + '/medicationAllergies/search?query='
    medications = this.lyricBaseUrl + '/medication/search?query='
    addAttachment = this.lyricBaseUrl + '/attachment/add/'
    createConsultation = this.lyricBaseUrl + '/consultation/createConsultation'
    getActive = this.lyricBaseUrl + '/consultation/getActive'
    memberCancel = this.lyricBaseUrl + '/consultation/diagnostic/memberCancel'
    healthRecords = this.lyricBaseUrl + '/healthRecords'
    providerImage = this.lyricBaseUrl + '/attachment/streamPhysicianProfile/inline'
    lyricUrgentCareFaq = this.baseUrl + 'helpers/dropdownmasterfaqs/lyrictalktoadoctornow/'
    lyricOtherFaq = this.baseUrl + 'helpers/dropdownmasterfaqs/lyrictalktocounsellor/'
}