import {NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

import {ProgressBarComponent} from '../progressBar/progressBar';

@NgModule({
    imports:[FormsModule,ReactiveFormsModule,CommonModule],
    declarations:[ProgressBarComponent],
    exports:[ProgressBarComponent],
    providers:[],
    
})
export class ProgressBarModule{
    constructor(){};
}