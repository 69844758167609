import {Injectable} from '@angular/core';
import { map } from 'rxjs-compat/operator/map';
import {StatesService} from '../service/states.service' 
import {ApiListService} from '../service/apiList.service'
import {ServicePageService} from '../service/servicePage.service';
import { CookieService } from 'ngx-cookie-service';
import {MyAccountService} from '../service/myAccount.service'
declare var $:any;
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
    providedIn:'root'
})
export class CommanHelperService 
{
    profileCompleate;progressbar;shimmer;
    public showStatusFilter = true;
    public showLocationFilter = false;
    showDateFilter = false;
    public selected_dependent_id;
    public from_appointment = false;
    public from_appointmentnewadd = false;
   

    public callFromHome=false;
    public switchFromMore=false;
    public childSwitched;
    public parentSwitched=false;

    public fromBehaviouralHealth = false;
    constructor(private states:StatesService,private servicePageService:ServicePageService,private cookie:CookieService,private api:ApiListService
        ,private myAccount:MyAccountService,private spinner:NgxSpinnerService){
            this.yearListfun();
        }


    closeRightFilter() {
        $('.rightFilter').css('width', '0');
        $('.rightFilter').css('min-width', '0px');
    }
    
    openLeftFilter() {
        this.showStatusFilter = true;
        this.showLocationFilter = false;
        this.showDateFilter = false;
        $('.rightFilter').css('width', '31%');
        $('.rightFilter').css('min-width', '300px');
    }
    
    getNotificationOptionObj() {
        return {
          position: ['top', 'right'],
          timeOut: 5000,
          lastOnBottom: false,
          clickToClose: true,
          showProgressBar: true,
          pauseOnHover: true,
          maxLength: 100,
          animate: 'scale',
          maxStack : 1
        };
    }

    profile;
    profiledata(data){
        this.profile=data;
    }

    catogray;
    wallateCatogray(event){
        this.catogray=event;
    }

    progressbarEnd(){
        this.profileCompleate=100;
        setTimeout(()=>{
            this.progressbar=false;
        },1000);
    
    }

    progressbarStart(){
        this.profileCompleate=10;
        this.progressbar=true;
    }

    shimmerEnd(){
        setTimeout(()=>{
            this.shimmer=false;
        },1000);
    }

    shimmerStart(){
        this.shimmer=true;
    }



  GoTOFaq(){

    if(this.api.baseUrl == 'https://allyhealth-back.theclientdemos.com/api/v2/') {
      window.open('https://allyhealth.theclientdemos.com/#/home/faq', '_blank');
    } else if(this.api.baseUrl == 'https://app.allyhealth.app/api/v2/') {
      window.open('https://allyhealth.app/#/home/faq', '_blank');
    }
    
  }
  
    yearList:any=[];
    yearListfun(){
        for (let i=0;i<=122;i++)
        {
            this.yearList[i]=(new Date().getFullYear()-i);
        }
    } 

    handleMonthValueChange(event){
        setTimeout(()=>{
          if(event.targetElement.value.length==8){
            var first = event.targetElement.value.slice(0,2);
            var second = event.targetElement.value.slice(2,4);
            var third = event.targetElement.value.slice(4);
            event.targetElement.value = '0' + first + '0' + second + third;
          }
          if(event.targetElement.value.length==9){
            var month = event.targetElement.value.substr(0,(event.targetElement.value).indexOf('/'))
            if(month.length==1){
              var first = event.targetElement.value.slice(0,2);
              var second = event.targetElement.value.slice(2,5);
              var third = event.targetElement.value.slice(5);
              event.targetElement.value = '0' + first + second  + third;
            }
            if(month.length==2){
              var first = event.targetElement.value.slice(0,3);
              var second = event.targetElement.value.slice(3,5);
              var third = event.targetElement.value.slice(5);
              event.targetElement.value = first + '0' + second  + third;
            }
          }
        },20)
      }
      handleMonthValueChangeOnBlur(event){
        setTimeout(()=>{
          if(event.target.value.length==8){
            var first = event.target.value.slice(0,2);
            var second = event.target.value.slice(2,4);
            var third = event.target.value.slice(4);
            event.target.value = '0' + first + '0' + second + third;
          }
          if(event.target.value.length==9){
            var month = event.target.value.substr(0,(event.target.value).indexOf('/'))
            if(month.length==1){
              var first = event.target.value.slice(0,2);
              var second = event.target.value.slice(2,5);
              var third = event.target.value.slice(5);
              event.target.value = '0' + first + second  + third;
            }
            if(month.length==2){
              var first = event.target.value.slice(0,3);
              var second = event.target.value.slice(3,5);
              var third = event.target.value.slice(5);
              event.target.value = first + '0' + second  + third;
            }
          }
        },20)
      }
      
    switchUser(userData){
        this.states.bmi.height=userData.height;
        this.states.bmi.weight=userData.weight;
        this.states.user.first_name=userData.name;
        localStorage.setItem('user',encodeURIComponent(JSON.stringify(userData)));
        localStorage.setItem('currentlocation',JSON.stringify(userData.state));
            
      }

    routerId:any=0
    
    set routerIdValue(value:any) {     
      this.routerId = value
    }
    get routerIdValue():any {
      return this.routerId;
    }
}