import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginRoutingModule} from './login.routes'
import {LoginComponent} from './login.component';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SimpleNotificationsModule } from 'angular2-notifications';

import {AngularMaterialModule} from '../angularMaterial.module';
import {CookieService } from 'ngx-cookie-service';

import {ProgressBarModule} from '../progressBar/progressBar.module'
import {ApiListService} from '../service/apiList.service';

import {AuthGuardfor} from '../service/authGuard.service';
import {AuthService} from '../service/auth.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { Ng2DeviceDetectorModule } from 'ng2-device-detector';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgOtpInputModule } from 'ng-otp-input';
import { WindowService } from '../service/windo.service';
import { AngularFireModule } from 'angularfire2';
import { environment } from 'src/environments/environment';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
@NgModule({
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    LoginRoutingModule,AngularMaterialModule,
    SimpleNotificationsModule,ProgressBarModule,NgxSpinnerModule,Ng2DeviceDetectorModule,
    Ng2TelInputModule,
    NgOtpInputModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
  ],
  declarations: [
    LoginComponent
  ],
  exports: [
    LoginComponent
  ],
  providers:[ApiListService,CookieService,AuthGuardfor,AuthService,WindowService]
 
})
export class LoginModule {}
