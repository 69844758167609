// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export let environment:any;

if(window.location.host.includes('localhost') || window.location.host=='10.5.7.161:4200'){
  environment = {
    production: false,
    firebase: {
      apiKey: "AIzaSyAMDWIL6LOFS2bJcV-DzW5LEkhJff4h3Ws",
      authDomain: "benefits-navigator.firebaseapp.com",
      projectId: "benefits-navigator",
      storageBucket: "benefits-navigator.appspot.com",
      messagingSenderId: "863616485810",
      appId: "1:863616485810:web:c9ea90885aa10b7fa2bcab",
      measurementId: "G-BDGBYJEPDK"
      
    },
    APIEndpoint: 'https://allyhealth-back.theclientdemos.com/api/v3/',
    lyricEndpoint:'https://staging.getlyric.com/go/api',
    APIEndpointMDLive: 'https://stage-rest-api.mdlivetechnology.com/',
  };
  }
  else if(window.location.host.includes('benefitsnavigator')){
     environment = {
      production: false,
      firebase: {
        apiKey: "AIzaSyAMDWIL6LOFS2bJcV-DzW5LEkhJff4h3Ws",
        authDomain: "benefits-navigator.firebaseapp.com",
        projectId: "benefits-navigator",
        storageBucket: "benefits-navigator.appspot.com",
        messagingSenderId: "863616485810",
        appId: "1:863616485810:web:c9ea90885aa10b7fa2bcab",
        measurementId: "G-BDGBYJEPDK"
        
      },
      APIEndpoint: 'https://allyhealth-back.theclientdemos.com/api/v3/',
      lyricEndpoint:'https://staging.getlyric.com/go/api',
      APIEndpointMDLive: 'https://stage-rest-api.mdlivetechnology.com/',
    };
    
  }
  else if(window.location.host.includes('allyhealth')){
     environment = {
      production: false,
      firebase: {
        apiKey: "AIzaSyBKh7QUV5pyZqnm7QXM03UJTaxT1jtWnY4",
        authDomain: "allyhealth-6e47a.firebaseapp.com",
        databaseURL: "https://allyhealth-6e47a.firebaseio.com",
        projectId: "allyhealth-6e47a",
        storageBucket: "allyhealth-6e47a.appspot.com",
        messagingSenderId: "510679352793",
        appId: "1:510679352793:web:42758a23f7e69f0bb205c8"
      },
      APIEndpoint: 'https://allyhealth-back.theclientdemos.com/api/v3/',
      lyricEndpoint:'https://staging.getlyric.com/go/api',
      APIEndpointMDLive: 'https://stage-rest-api.mdlivetechnology.com/',
    };
    
  }

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.



