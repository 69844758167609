import 
{
    
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatInputModule,
    MatSelectModule,MatListModule,
    MatRadioModule,MatNativeDateModule,MatTableModule,MatIconModule,MatGridListModule,MatSlideToggleModule,MatTooltipModule, MatFormFieldModule



  } from '@angular/material';
  
  
  import {NgModule} from '@angular/core'
  import {CdkTableModule} from '@angular/cdk/table';
  
  
  @NgModule({
    imports: [
        
        MatAutocompleteModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatInputModule,
        MatSelectModule,MatListModule,
        MatRadioModule,MatNativeDateModule,MatTableModule,MatIconModule,MatGridListModule,MatSlideToggleModule,MatTooltipModule,MatFormFieldModule
      ],
    exports: [
        
        MatAutocompleteModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatInputModule,
        MatSelectModule,MatListModule,
        MatRadioModule,MatNativeDateModule,MatTableModule,MatIconModule,MatGridListModule,MatSlideToggleModule,MatTooltipModule,MatFormFieldModule
      ]
  })
  export class AngularMaterialModule {}