import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { ApiListService } from 'src/app/service/apiList.service';
import { FlavourDomainService } from 'src/app/service/flavour-domain.service';
import { LoginService } from 'src/app/service/login.service';


export interface ConfirmModel {
  title:string;
  message:string;
  okBtn?:string;
}

@Component({
  selector: 'src-modal-del',
  templateUrl: './modal-del.component.html',
  styleUrls: ['./modal-del.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalDelComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel {
    title: string = '';
    message: string = '';
    okBtn: string = 'OK';
    
  constructor(public dialogService: DialogService,private router:Router, private FlavourDomain:FlavourDomainService) {
    super(dialogService);
  }
 
  confirm(){
    
    this.router.navigate(['/']);
    this.close();
    
  }
  

}
