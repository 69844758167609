import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';

@Injectable({providedIn:'root'})
export class StatesService 
{

  step5NextDissable:boolean;
  pharmacy_search;filterZipCode;
  public parmacyFilter={
    state:'',
    city:''
  }

  ProviderIdForAppointmentCreation;
  timedisplay;

  public chief_complaint : any = {
    'symptoms' : ''
  };

  public sessionExpiredMessage = 'Your session has expired due to inactivity. Please sign in again to continue. You are redirecting to the login screen.'

  health_history_initial:any= {
    "health_history": {
      "medical_history": {
        "allergies": '',
        "medications": '',
        "surgeries": '',
        "medical_conditions": ''
      },
      "pediatric_profile": {
        "Birth_complications": '',
        "Childcare_outside_home": '',
        "Current_Diet": null,
        "Immunization_up_to_date": '',
        "Siblings": '',
        "Smoking_exposure": '',
        "Current_weight_in_lbs":null,
        "Birth_complications_explanation": '',
        "Last_shot": '',
      },
      "personal_information": {
        "is_breast_feeding": null,
        "is_pregnant": null,
        "menstrual_cycle": null
      }

  }
};

  health_history:any= {
      "health_history": {
        "medical_history": {
          "allergies": '',
          "medications": '',
          "surgeries": '',
          "medical_conditions": ''
        },
        "pediatric_profile": {
          "Birth_complications": '',
          "Childcare_outside_home": '',
          "Current_Diet": null,
          "Immunization_up_to_date": '',
          "Siblings": '',
          "Smoking_exposure": '',
          "Current_weight_in_lbs":null,
          "Birth_complications_explanation": '',
          "Last_shot": '',
        },
        "personal_information": {
          "is_breast_feeding": null,
          "is_pregnant": null,
          "menstrual_cycle": null,
        }
  
    }
  };

  health_history_post:any= {
    "health_history": {
      "medical_history": {
        "allergies": '',
        "medications": '',
        "surgeries": '',
        "medical_conditions": ''
      },
      "pediatric_profile": {
        "Birth_complications": '',
        "Childcare_outside_home": '',
        "Current_Diet": null,
        "Immunization_up_to_date": '',
        "Siblings": '',
        "Smoking_exposure": '',
        "Current_weight_in_lbs":null,
        "Birth_complications_explanation": '',
        "Last_shot": '',
      },
      "personal_information": {
        "is_breast_feeding": null,
        "is_pregnant": null,
        "menstrual_cycle": null,
      }
  }
};

 behavioral_history:any= {
    "behavioral_history": {
      "behavioral_health_reasons": [
        {
          "condition": "Emotional Problem",
          "active":false
        },
        {
          "condition": "Substance Abuse",
          "active":false
        },
        {
          "condition": "Relationship Issue",
          "active": false
        },
        {
            "condition": "Other",
            "active": false
        }
      ],
      "behavioral_mconditions": [
        {
          "condition": "Alcohol Dependence",
          "active": true
        },
        {
            "condition": "Obsessive Compulsive Disorder (OCD)",
            "active": false
        },
        {
            "condition": "Anxiety",
            "active": false
        },
        {
            "condition": "Panic Attacks",
            "active": false
        },
        {
            "condition": "Bipolar Disorder",
            "active": false
        },
        {
            "condition": "Schizophrenia",
            "active": false
        },
        {
            "condition": "Depression",
            "active": false
        },
        {
            "condition": "Substance Abuse",
            "active": false
        }
      ],
      "behavioral_family_history": [
        {
          "condition": "Alcohol Dependence",
          "active": false
        },
        {
          "condition": "Obsessive Compulsive Disorder (OCD)",
          "active": false
        },
        {
            "condition": "Bipolar Disorder",
            "active": false
        },
        {
            "condition": "Schizophrenia",
            "active": false
        },
        {
            "condition": "Depression",
            "active": false
        },
        {
            "condition": "Substance Abuse",
            "active": false
        }
      ],
      "behavioral_health_description": '',
      "hospitalized": null,
      "family_hospitalized": null,
      "hospitalized_date": "",
      "hospitalized_duration": "",
      "counseling_preference": '',
    }
  }
  

familyDepandent={
    "firstName":"",
    "lastName":"",
    "gender":"",
    "street":"",
    "city":"",
    "state":"",
    "zipcode":"",
    "birthdate":"",
    "contact_number":"",
    "email":"",
    "username":"",
    "relationship":"",
    "is_enrolled" : false,
    "subscriber_id" : null
}



  public states=
  [
      {value:"Alabama",	Abbreviation:"AL" ,  id:1},

      {value:"Alaska",	 Abbreviation:"AK", id:2},

      {value:"Arizona",	Abbreviation:"AZ",  id:3},

      {value:"Arkansas",	Abbreviation:"AR",  id:4},

      {value:"California",	Abbreviation:"CA",  id:5},

      {value:"Colorado",	Abbreviation:"CO",  id:6},

      {value:"Connecticut",	Abbreviation:"CT",  id:7},

      {value:"Delaware",	Abbreviation:"DE",  id:8},

      {value:"District of Columbia",	Abbreviation:"DC",  id:9},

      {value:"Florida",	Abbreviation:"FL",  id:10},

      {value:"Georgia",	Abbreviation:"GA",  id:11},

      {value:"Hawaii",	Abbreviation:"HI",  id:12},

      {value:"Idaho",	Abbreviation:"ID",    id:13},

      {value:"Illinois",	Abbreviation:"IL",  id:14},

      {value:"Indiana",	Abbreviation:"IN",  id:15},

      {value:"Iowa",	Abbreviation:"IA",      id:16},

      {value:"Kansas",	Abbreviation:"KS",  id:17},

      {value:"Kentucky",	Abbreviation:"KY",  id:18},

      {value:"Louisiana",	Abbreviation:"LA",  id:19},

      {value:"Maine",	Abbreviation:"ME",id:   20},

      {value:"Maryland",	Abbreviation:"MD",  id:21},

      {value:"Massachusetts",	Abbreviation:"MA",id:22},

      {value:"Michigan",	Abbreviation:"MI",  id:23},

      {value:"Minnesota",	Abbreviation:"MN",  id:24},

      {value:"Mississippi",	Abbreviation:"MS",  id:25},

      {value:"Missouri",	Abbreviation:"MO",  id:26},

      {value:"Montana",	Abbreviation:"MT",  id:27},

      {value:"Nebraska",	Abbreviation:"NE",  id:28},

      {value:"Nevada",	Abbreviation:"NV",  id:29},

      {value:"New Hampshire",	Abbreviation:"NH",id:30},

      {value:"New Jersey",	Abbreviation:"NJ",id:31},

      {value:"New Mexico",	Abbreviation:"NM",id:32},

      {value:"New York",	Abbreviation:"NY",id:33},

      {value:"North Carolina",	Abbreviation:"NC",id:34},

      {value:"North Dakota",	Abbreviation:"ND",id:35},

      {value:"Ohio",	Abbreviation:"OH",id:36},

      {value:"Oklahoma",	Abbreviation:"OK",id:37},

      {value:"Oregon",	Abbreviation:"OR",id:38},

      {value:"Pennsylvania",	Abbreviation:"PA",id:39},

      {value:"Rhode Island",	Abbreviation:"RI",id:40},

      {value:"South Carolina",	Abbreviation:"SC",id:41},

      {value:"South Dakota",	Abbreviation:"SD",id:42},

      {value:"Tennessee",	Abbreviation:"TN",id:43},

      {value:"Texas",	Abbreviation:"TX",id:44},

      {value:"Utah",	Abbreviation:"UT",id:45},

      {value:"Vermont",	Abbreviation:"VT",id:46},

      {value:"Virginia",	Abbreviation:"VA",id:47},

      {value:"Washington",	Abbreviation:"WA",id:48},

      {value:"West Virginia",	Abbreviation:"WV",id:49},

      {value:"Wisconsin",	Abbreviation:"WI",id:50},

      {value:"Wyoming",	Abbreviation:"WY",id:51},

      {value:"American Samoa",	Abbreviation:"AM",id:52},

      {value:"Guam",	Abbreviation:"GU",id:54},

      {value:"Marshall Islands",	Abbreviation:"MH",id:55},

      {value:"Northern Mariana Islands",	Abbreviation:"MP",id:56},

      {value:"Palau",	Abbreviation:"PW",id:57},

      {value:"Puerto Rico",	Abbreviation:"PR",id:58},

      {value:"Virgin Islands",	Abbreviation:"VI",id:59},

      {value:"Armed Forces Europe",	Abbreviation:"AE",id:60},

  ]

  public states2 = 
  [
    {
        "id": 1,
        "country": 1,
        "name": "New York"
    },
    {
        "id": 2,
        "country": 1,
        "name": "California"
    },
    {
        "id": 3,
        "country": 1,
        "name": "Illinois"
    },
    {
        "id": 4,
        "country": 1,
        "name": "Florida"
    },
    {
        "id": 5,
        "country": 1,
        "name": "Texas"
    },
    {
        "id": 6,
        "country": 1,
        "name": "Pennsylvania"
    },
    {
        "id": 7,
        "country": 1,
        "name": "Georgia"
    },
    {
        "id": 8,
        "country": 1,
        "name": "District of Columbia"
    },
    {
        "id": 9,
        "country": 1,
        "name": "Massachusetts"
    },
    {
        "id": 10,
        "country": 1,
        "name": "Arizona"
    },
    {
        "id": 11,
        "country": 1,
        "name": "Michigan"
    },
    {
        "id": 12,
        "country": 1,
        "name": "Washington"
    },
    {
        "id": 13,
        "country": 1,
        "name": "Minnesota"
    },
    {
        "id": 14,
        "country": 1,
        "name": "Colorado"
    },
    {
        "id": 15,
        "country": 1,
        "name": "Maryland"
    },
    {
        "id": 16,
        "country": 1,
        "name": "Nevada"
    },
    {
        "id": 17,
        "country": 1,
        "name": "Missouri"
    },
    {
        "id": 18,
        "country": 1,
        "name": "Oregon"
    },
    {
        "id": 19,
        "country": 1,
        "name": "Puerto Rico"
    },
    {
        "id": 20,
        "country": 1,
        "name": "Ohio"
    },
    {
        "id": 21,
        "country": 1,
        "name": "Indiana"
    },
    {
        "id": 22,
        "country": 1,
        "name": "North Carolina"
    },
    {
        "id": 23,
        "country": 1,
        "name": "Virginia"
    },
    {
        "id": 24,
        "country": 1,
        "name": "Wisconsin"
    },
    {
        "id": 25,
        "country": 1,
        "name": "Rhode Island"
    },
    {
        "id": 26,
        "country": 1,
        "name": "Utah"
    },
    {
        "id": 27,
        "country": 1,
        "name": "Tennessee"
    },
    {
        "id": 28,
        "country": 1,
        "name": "Kentucky"
    },
    {
        "id": 29,
        "country": 1,
        "name": "Louisiana"
    },
    {
        "id": 30,
        "country": 1,
        "name": "Connecticut"
    },
    {
        "id": 31,
        "country": 1,
        "name": "Oklahoma"
    },
    {
        "id": 32,
        "country": 1,
        "name": "Hawaii"
    },
    {
        "id": 33,
        "country": 1,
        "name": "Nebraska"
    },
    {
        "id": 34,
        "country": 1,
        "name": "New Mexico"
    },
    {
        "id": 35,
        "country": 1,
        "name": "Alabama"
    },
    {
        "id": 36,
        "country": 1,
        "name": "South Carolina"
    },
    {
        "id": 37,
        "country": 1,
        "name": "Iowa"
    },
    {
        "id": 38,
        "country": 1,
        "name": "Kansas"
    },
    {
        "id": 39,
        "country": 1,
        "name": "Arkansas"
    },
    {
        "id": 40,
        "country": 1,
        "name": "Idaho"
    },
    {
        "id": 41,
        "country": 1,
        "name": "Mississippi"
    },
    {
        "id": 42,
        "country": 1,
        "name": "New Jersey"
    },
    {
        "id": 43,
        "country": 1,
        "name": "Alaska"
    },
    {
        "id": 44,
        "country": 1,
        "name": "New Hampshire"
    },
    {
        "id": 45,
        "country": 1,
        "name": "Maine"
    },
    {
        "id": 46,
        "country": 1,
        "name": "North Dakota"
    },
    {
        "id": 47,
        "country": 1,
        "name": "West Virginia"
    },
    {
        "id": 48,
        "country": 1,
        "name": "South Dakota"
    },
    {
        "id": 49,
        "country": 1,
        "name": "Delaware"
    },
    {
        "id": 50,
        "country": 1,
        "name": "Montana"
    },
    {
        "id": 51,
        "country": 1,
        "name": "Vermont"
    },
    {
        "id": 52,
        "country": 1,
        "name": "Wyoming"
    }
]

  public providerType=
  [
      {id:2,value:"Pediatrician"},
      {id:3,	value:"Family Physician"},
      {id:5,	value:"Therapist"},
      {id:6,	value:"Psychiatrist"}
  ]

  public gender=
  [
    {id:1,value:"Male"},
    {id:2,value:"Female"}
  ]

  public obj=
  { 
    doc: 
    {
      document_name: "image.jpeg",
      data:'',
      mime_type: "image/png",
      record_type: "Patient Record"
    }
  }

  public serchObj=
  {
    "search": 
    {
      "term": " ",
      "limit": 10
    }
      
  }

  public midactionCreat:any=
  {
    "medication": 
    {
      "name": null,
      "current": true,
      "dosage": "",
      "frequency":null
    }
  }

  public allergy=
  {
    "allergy": {
      "name": null,
      "severity": "Select Severity",
      "reaction": null
    }
  }

  public medical_condition=
  {
    "medical_condition": {
      "condition": "",
      "source": "Self Reported",
      "concept_id": "",
      "snomed": "",
      "icd10": ""
    }
  }

  public surgery=
  {
    "surgery": {
      "name": "",
      "surgery_year": "Select Surgery Year"
    }
  }

  public serchProvider:any=
  { 
    "search": 
    {
      "state_abbrev": " ",
      "availability_type": ["phone","video"],
      "gender":"",
      "specific_date":"",
      "provider_type_id":3,
    } 
  }
  
  public cardDetal=
  {
    "credit_card": 
    {
      "billing_data": 
      {
        "name": "",
        "address1": null,
        "address2": null, 
        "state_abbrev": null,
        "city": null,
        "zip": null
      },
      "card_data": 
      {
        "card_number": "",
        "cvv": "",
        "expiration_month": "",
        "expiration_year": "",
      }
    }
  }
  
  public cost:number;
  public parmacydoc=
  {
    "city": " ", 
    "state": " ", 
    "zipcode":" ",
    "radius": 10000, 
    "twenty_four_hour_pharmacy": false,
    "coordinates": 
    { 
      "latitude": " ", "longitude": " " 
    }
  }

  public appoinmentdoc:any=
  {
    "appointment": 
    {
      "provider_type_id": null,
      "state_abbrev": "AK",
      "customer_call_in_number": "5555555555",
      "start_time": null,
      "survey_answer": null,
      "appointment_method": " ",
      "chief_complaint": "",
      "concept_id": 64,
      "agrees_to_informed_consent": true,
      "agrees_to_privacy_agreement": true,
      "consent_user_initials": "tT",
      'pcp_questions':[
        { 
          "question": "primary_care_physician", 
          "answer": true
        },
        {
          "question": "pcp_correct_information", 
          "answer": true 
        },
        { 
          "question": "pcp_visit_information", 
          "answer": false 
        },
        { 
          "question": "pcp_assistance_question", 
          "answer": true 
        } 
      ]
    }
  }

  public scheduleAppointment : any =
  {
    "appointment" :
    {
      "provider_name" : null,
      "appointment_id" : null,
      "appointment_by" : null,
      "appointment_start_date" : null,
      "appointment_detail" : null
    }
  }

  public cancelAppointmentMail : any =
  {
    "appointment" :
    {
      "provider_name" : null,
      "appointment_id" : null,
      "appointment_by" : null,
      "appointment_start_date" : null,
      "appointment_detail" : null
    }
  }

  public categories = ["Emergency Room", "Urgent Care", "Primary Care Physician", "Delay Seeking Care", "Other"];
  public BnCategories=["Another Online Therapy Company","In-Person Psychiatrist","In-Person Therapist","Primary Care Physician","Urgent Care","Emergency Room","Intensive Outpatient / Partial Hospital Program","Delay Seeking Care"]
  public pharmacyadd=
  {
    "patient": 
    {
      "first_name": "test1",
      "last_name": "Patient1",
      "pharmacy_id":"30752",
      "completed_medical_history":true,
    }
  }

  public user:any=
  {

    "relationship":null,
    "first_name": null,
    "last_name":null,
    "email": null,
    "username": null,
    "gender": null,
    "phone": null,
    "emergency_contact_number":null,
    "address1": "1313 Mockingbird Lane",
    "city": "New York",
    "state": null,
    "zip": null,
    "birthdate": null,
    "is_enrolled":false,
    "subscriber_id" : null,
    "accountId":null,
  }

  public userProfile=
  {
      "id": 0,
      "email": null,
      "contact_number": null,
      "zipcode":null,
      "lastName":null,
      "birthdate":null,
      "name": null,
      "subscriber_id": null,
      "username":null,
      "gender":null,
      "street": null,
      "city": null,
      "state": null,
      "state_id":null,
      "image": null,
      "is_active": true,
      "firstName": null,
      "api_token": null,
  
      "mdlive_service_api": {
        "personal_health": false,
        "health_wellness_coaching": true,
        "consult_a_specialist": false,
        "talk_to_a_counselor": true,
        "talk_to_a_doctor": false
      },
      "mdlive_user": {
        "id": 0,
        "m_patient_id": null,
        "m_first_name": null,
        "m_last_name": null,
        "m_middle_name": null,
        "m_username": null,
        "m_address1": null,
        "m_address2": null,
        "m_phone": null,
        "m_work_phone": null,
        "m_affiliation_id": null,
        "m_jwt_token": null,
        "m_istoken": true,
        "m_token1": null,
        "m_token2": null
      }
    
  }

  public insurance=
  {
    "insurance": {
      "insurance_details": {
        "insurance_payer_id": 27,
        "member_id": "MDL684088116",
        "is_dependent": false
      },
      "primary_insurance_details": {
        "relationship": "Other",
        "first_name":  "gane",
        "last_name": "jee",
        "gender": "Male",
        "dob": "10/12/2010",
        "member_id": "MDL684088116"
      }
    }
  }

  public insuranceInfo : any =
  {
    "health_insurance_provider" : null,
    "health_insurance_member_id" : null,
    "health_insurance_grp_number" : null,
    "health_insurance_grp_name" : null,
    "rx_bin" : null,
    "rx_PCN" : null,
    "rx_grp" : null,
    "insurance_firstName" : null,
    "insurance_lastName" : null,
    "insurance_birthdate" : null,
    "insurance_gender" : null,
    "insurance_street" : null,
    "insurance_city" : null,
    "insurance_state" : null,
    "insurance_zipcode" : null,
    "insurance_contact_number" : null,
    "same_as_primary" : false
  }

  public Languages=
  [
    { id: 1	, language:"English",	Alpha :"eng"},
    { id: 2	, language:"Spanish",	Alpha :"spa"},
    { id: 3	, language:"French",	Alpha :"fre"},
    { id: 4	, language:"German",	Alpha :"ger"},
    { id: 5	, language:"Italian",	Alpha :"ita"},
    { id: 7	, language:"Polish",	Alpha :"pol"},  
    { id: 8	, language:"Korean",	Alpha :"kor"},
    { id: 9	, language:"Vietnamese",	Alpha :"vie"},  
    { id: 11	, language:"Russian",	Alpha :"rus"},
    { id: 12	, language:"Chinese",	Alpha :"chi"},
    { id: 14	, language:"Arabic",	Alpha :"ara"},
    { id: 15	, language:"Hindi",	Alpha :"hin"},
    { id: 16	, language:"Persian",	Alpha :"per"},
    { id: 17	, language:"Malayalam",	Alpha :"mal"},
    { id: 18	, language:"Hebrew",	Alpha :"heb"},
    { id: 19	, language:"Yiddish",	Alpha :"yid"},
    { id: 20	, language:"Greek",	Alpha :"gre"},  
    { id: 21	, language:"Gujarati",	Alpha :"guj"},
    { id: 22	, language:"Haitian Creole",	Alpha :"hat"},
    { id: 23	, language:"Igbo",	Alpha :"ibo"},
    { id: 24	, language:"Lithuanian",	Alpha :"lit"},
    { id: 25	, language:"Panjabi",	Alpha :"pan"},
    { id: 26	, language:"Pushto",	Alpha :"pus"},
    { id: 27	, language:"Romanian",	Alpha :"rum"},
    { id: 28	, language:"Telugu",	Alpha :"tel"},
    { id: 29	, language:"Ukrainian",	Alpha :"ukr"},
    { id: 30	, language:"Urdu",	Alpha :"urd"},
  
  ]

  public helpSupport=
  {
    "firstName":'',
    "email":''
  }

  public helpSupportPost=
  {
    "description":'',
  }
  
  public edocPostMessage:any=
  {
    "route_id": null,
    "subject":null,
    "body":null
  }

  public prescription=
  {
    Prescription:'',
    Form:'',
    Dosage:'',
    Quantity:'',
    Pharmacies:'',
  }

  public registration=
  {
    "email":'',
    "password":'',
    "contact_number":'',
    "zipcode":'',
    "lastName":'',
    "birthdate":'',
    "name":'',
    "contact_Id":'',
    "subscriber_id":'',
    "accountId":'',
    "contact_CreatedDate":'',
    "health_insurance_provider":null,
    "health_Insurance_Member_ID":null,
    "gender" : '',
    "street" : '',
    "city": '',
    "state" : '',
    "image" : '',
    "firstName":'',
    "relationship":"Self",
    "primary_or_dependent":"",
    "mdlive_id":null,
    "member_id" : '',
    "username":"",
    "primaryNumber":"",


    "personalHealth":false,
    "healthwellness_coaching": true,
    "consult_specialist":false,
    "talk_to_counselor":true,
    "talk_to_doctor":false,
    "permission_allow_to_primary":false,
    "is_primary":true,

  };

 public service=
 [
    {id:"Not Sure"}, 
    {id:"Mild"}, 
    {id:"Moderate"}, 
    {id:"Severe"}
 ]
  public Prescription = 
  [
    { drugName : 'ALBUTEROL SULFATE' },
    { drugName : 'AMOXICILLIN' },
    { drugName : 'AUGMENTIN' },
    { drugName : 'BENZONATATE' },
    { drugName : 'CIALIS' },
    { drugName : 'MACROBID' },
    { drugName : 'MEDROL' },
    { drugName : 'PREDNISONE' },
    { drugName : 'TAMIFLU' },
    { drugName : 'ZITHROMAX' }
  ]

  public Form=[];
  public Dosage=['1MG',' 2MG',' 3MG',' 4MG']
  public Quantity=['10','20','30','30','40',]
  public PharmaciesNear=['Philadelphia','Philadelphia','Philadelphia','Philadelphia','Philadelphia']
  public pharmacyList=[1,2,3,4,5,6,7]
  appointmentTypeDropDown=['phone','video'];

  lifeStyle=
  {
    "id":28,
    "sleep_daily":false,
    "excercise":false,
    "yearly_flu_shots":false,
    "smoke_or_tobacco":false,
    "alcohol":false,
    "recreational_drugs":false,
    "disabled":false,
    "bmi_obj":
    {
      "weight":90,
      "height":50
    }
  }

  bmi:any=
  {
    "BMI":null
  }
  passwordChange=
  {
    "new_password":null
  }
 
  ProviderProfile;
  
  public userLogin={"username":"","password":""}
  
 public preenrolledRegister={
    "username": null,
        "health_Insurance_Member_ID": null,
        "primary_or_dependent": "Dependent Member",
        "relationship": null,
        "subscriber_id": null,
        "healthwellness_coaching": false,
        "contact_CreatedDate": null,
        "enrolled_and_registered": false,
        "parent_subscription_id": null,
        "street": null,
        "birthdate": null,
        "consult_specialist": false,
        "gender": null,
        "city": null,
        "mdlive_id": null,
        "name": null,
        "firstName": null,
        "lastName": null,
        "talk_to_counselor": false,
        "zipcode": null,
        "talk_to_doctor": false,
        "state":null,
        "emergency_contact_number": null,
        "contact_number": null,
        "personalHealth": false,
        "email": null,
  }

  EmailSettingUpdate={
      "product_allyHealth_updates":false,
      "alerts_reminders":false,
      "appointment_reminders_updates":false,
      "message_alerts":false,
      "monthlynews":false    
    }
    PushNotUpdate={
        "productUpdate":false,
        "alerts_reminders":false,
        "appointment_reminders_updates":false,
        "message_alerts":false
    }

    public messageCount : any = {
      "customer_support_notification_count" : 0,
      "mdlive_notification_count" : 0,
      "total_notification_count" : 0
    }

    public readMsgPost : any = {
      "mdlive_read_count" : 0,
      "customer_support_read_count" : 0,
      "case_id" : null
    };
    public keyChangeEvent(e){
      if(e.key=="'"){
        e.target.value= e.target.value.replace(/'/g,"’");
      }
    }
}
