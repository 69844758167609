import {Component} from '@angular/core';
import {CommanHelperService} from '../service/commanHelper.service'
@Component({
    selector:'progress-bar',
    templateUrl:'../progressBar/progressBar.html',
    styleUrls:['../progressBar/progressBar.scss']

})
export class ProgressBarComponent{
    constructor( public commanHelperService:CommanHelperService){
    }
    profileCompleate = this.commanHelperService.profileCompleate;
    progressbar=this.commanHelperService.progressbar;
}