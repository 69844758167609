import {Injectable,OnInit} from '@angular/core';
import {HttpClientService} from './httpClient.service';
import {Router} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {ApiListService} from './apiList.service';

declare var $:any;

@Injectable({
    providedIn:'root'
})
export class MdliveService  implements OnInit
{
    constructor(private httpClient:HttpClientService ,private router:Router,private cookie:CookieService, private apiListService: ApiListService)
    {
        

    }
    ngOnInit() 
    {
     
     
    }
    
   
    getpatients(url)
    {
        return this.httpClient.getM(url);
    }
    postExtendjwt(url,obj){
        return this.httpClient.postMwithOutToken(url,obj);
    }
    postDoc(url,obj){
        return this.httpClient.postM(url,obj);
    }

    patchdata(url,obj){
        return this.httpClient.patchM(url,obj);
    }
 
    deletedata(url){
        return this.httpClient.deleteM(url)
    }
    putdata(url,obj){
        return this.httpClient.putM(url,obj)
    }
    get_geo_location_usingzipcode(zipcode) {
        return this.httpClient.get_lat_log_zipcode('https://maps.googleapis.com/maps/api/geocode/json?address=' + zipcode + '&key=AIzaSyBkwWSkW2ozsKbGfPJECUCByybtsgvY6IY');
    }
    get_distance_of_pharmacy(origin,destinations) {
        return this.httpClient.get_lat_log_zipcode('https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&origins='+origin+'&destinations='+destinations+'&key=AIzaSyBomwU1OtAXxqA0eXVyBWAMowAW0_vjkDQ');
    }


    get_Doctor_image (url) {
        return this.httpClient.getM( url);
    }


    get_appointement_history (url) {
        return this.httpClient.getM(url);
    }


    get_particular_appointement (url) {
        return this.httpClient.getM(url);
    }

}