import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

export interface ConfirmModelPopUp {
  title:string;
  message:string;
  okBtn?:string;
}
@Component({
  selector: 'src-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmPopupComponent extends DialogComponent<ConfirmModelPopUp, boolean> implements ConfirmModelPopUp {
  title: string = '';
  message: string = '';
  okBtn: string = 'OK';
  constructor(public dialogService: DialogService, private router:Router) { 
    super(dialogService);
  }

  ngOnInit() {
  }
  okay(){
    this.result = false;
    this.close();
  }
}
