import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { tap, delay } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from  '@angular/router'
@Injectable()
export class AuthService {
  isLoggedIn = false;
  getUserInfo;
  redirectUrl: string;
constructor(private cookieService:CookieService,private router: Router){
  this.getUserInfo = this.cookieService.get('token');
  
}

  loginAuth(): Observable<boolean> 
  {
    this.getUserInfo = this.cookieService.get('token');
    if(this.getUserInfo){
      return Observable.of(true);

    } else{
      return Observable.of(false);
    } 
  }

}