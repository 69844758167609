 import { Injectable }       from '@angular/core';
import {CanActivate, Router,ActivatedRouteSnapshot,RouterStateSnapshot,CanActivateChild,NavigationExtras,CanLoad, Route, CanDeactivate}from '@angular/router';
import { AuthService }      from './auth.service';
import { RegistrationComponent } from '../registration/registration.component';
import { Observable } from'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthGuardfor implements CanActivate, CanActivateChild, CanDeactivate<RegistrationComponent> {
  getUserInfo;
  constructor(private authService: AuthService, private router: Router,private cookieService:CookieService) {
    this.getUserInfo = this.cookieService.get('token');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;
    if(url!='/registration'){
      return this.checklogin(url);
    }
    if(url=='/registration'){
      return true;
    }
  }

  canDeactivate(component:RegistrationComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot): boolean{
    if(component.gotoLogin==true || this.getUserInfo){
      return true;
      }
     
      else{
        history.pushState({}, "", currentState.url);
        return false
      }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
  checklogin(url):any{
  let isLogin ;
   this.authService.loginAuth().subscribe(rec=>{
      if(rec){
        isLogin = true;
      }else{
        this.router.navigate(['/']);
        isLogin = false;
      }
    });
    return isLogin;
  }

}