import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login.component';
import {AuthGuardfor} from '../service/authGuard.service';


const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  }, 
  {
    path: 'registration',
    loadChildren: '../registration/registration.module#RegistrationModule',
  },
  
  {
    path: 'forget-password/:id',
    loadChildren: '../forget-password/forget-password.module#ForgetPasswordModule',
  },
  {
    path:'home',
    loadChildren: '../home/home.module#HomeModule' ,canActivateChild:[AuthGuardfor],
  },
        
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule {
  constructor(){
  }
 }